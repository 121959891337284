import React, { Component } from 'react';
import {
    InputAdornment,
    Switch,
    FormGroup,
    FormControlLabel,
    TextField,
    FormControl,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Save, Delete } from '@material-ui/icons';
import { Node, Edge, Conversation } from '../models';
import { updateLink, updateConversation } from '../api';
import { get } from 'lodash';

const styles = theme => ({
    activeElementContainer: {
        height: '250px',
        margin: theme.spacing(1),
        width: 280,
    },
    elementName: {
        fontSize: 14,
        color: '#111',
        textAlign: 'center',
        overflow: 'hidden',
    },
    smallLabel: {
        fontSize: 14,
        color: '#111',
    },
});

class ActiveElementInfoPanel extends Component {
    state = {
        id: null,
        progressValue: 0,
        skipIfAnswered: false,
        isFirst: false,
    };

    saveElement = async ({ addTarget }) => {
        const { activeElement, replaceElement } = this.props;
        const {
            id,
            conversationItemId,
            conversationItemOptionId,
            nextConversationItemId,
            meta,
        } = activeElement;
        if (addTarget) {
            meta.data.sources.push({
                id: '' + meta.data.sources.length,
            });
        }
        const { skipIfAnswered, progressValue } = this.state;
        const { result } = await updateLink(
            new Node(
                {
                    id,
                    conversationItemId,
                    conversationItemOptionId,
                    nextConversationItemId,
                    skipIfAnswered,
                    progressValue,
                    meta,
                },
                true
            )
        );

        replaceElement({
            element: new Node(result),
            isDelete: false,
        });
    };

    deleteElement = async () => {
        const { activeElement, replaceElement } = this.props;
        replaceElement({ element: activeElement, isDelete: true });
    };

    updateConversation = async () => {
        const { isFirst } = this.state;
        const result = await updateConversation({
            conversation: new Conversation({
                ...this.props.activeConversation,
                firstItemId: this.props.activeElement.conversationItemId,
            }),
        });

        this.setState({
            ...this.state,
            isFirst: !isFirst,
        });

        //this will proc a change to update the activeConversation with the new firstItemId
        this.props.handleActiveConversationChange(result);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.activeElement.id !== this.props.activeElement.id) {
            const isFirstItem =
                this.props.activeConversation.firstItemId ===
                this.props.activeElement.conversationItemId
                    ? true
                    : false;
            this.setState({
                id: this.props.id,
                progressValue: this.props.activeElement.progressValue || 0,
                skipIfAnswered: this.props.activeElement.skipIfAnswered,
                isFirst: isFirstItem,
            });
        }
    }

    render() {
        const { classes, activeElement } = this.props;

        const { progressValue, skipIfAnswered, isFirst } = this.state;

        return (
            <div className={classes.activeElementContainer}>
                {activeElement instanceof Node ? (
                    <>
                        <span className={classes.elementName}>
                            {get(activeElement, 'meta.data.label')}
                        </span>
                        <FormControl component="fieldset">
                            <div style={{ display: 'inline-flex' }}>
                                <TextField
                                    label="progress"
                                    id="progressTextField"
                                    size="small"
                                    value={progressValue}
                                    variant="filled"
                                    InputProps={{
                                        style: { width: 100 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: { width: 100 },
                                    }}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            progressValue: value,
                                        })
                                    }
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                        this.saveElement({ addTarget: true })
                                    }
                                >
                                    Add Target
                                </Button>
                            </div>
                            <FormGroup>
                                <div>
                                    <FormControlLabel
                                        className={classes.smallLabel}
                                        control={
                                            <Switch
                                                checked={!!skipIfAnswered}
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        value={skipIfAnswered}
                                        onChange={() =>
                                            this.setState({
                                                skipIfAnswered: !skipIfAnswered,
                                            })
                                        }
                                        label="SkipIfAnswered?"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                checked={!!isFirst}
                                                color="primary"
                                                style={{ fontSize: 10 }}
                                            />
                                        }
                                        className={classes.smallLabel}
                                        style={{ fontSize: 10 }}
                                        value={isFirst}
                                        onChange={this.updateConversation}
                                        label="isFirst?"
                                        labelPlacement="start"
                                    />
                                </div>
                            </FormGroup>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    startIcon={<Save />}
                                    variant={'contained'}
                                    size={'small'}
                                    color={'primary'}
                                    onClick={this.saveElement}
                                >
                                    Save Element
                                </Button>
                                <Button
                                    size="small"
                                    onClick={this.deleteElement}
                                >
                                    <Delete />
                                    Delete
                                </Button>
                            </div>
                        </FormControl>
                    </>
                ) : activeElement && activeElement instanceof Edge ? (
                    <List>
                        <ListItem dense>
                            <ListItemText
                                primary={`${activeElement.meta.label}`}
                                secondary={
                                    <span>optionId:{activeElement.conversationItemOptionId ||'N/A'}<br /> routingId: {activeElement.id}</span>
                                }
                            />
                        </ListItem>
                        <ListItem dense button onClick={this.deleteElement}>
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            <ListItemText primary={`Delete Edge`} />
                        </ListItem>
                    </List>
                ) : (
                    <div> no active element selected </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(ActiveElementInfoPanel);
