export class Edge {
    constructor(edge) {
        const {
            id,
            conversationId,
            conversationItemId,
            conversationItemOptionId,
            nextConversationItemId,
            meta,
            type,
        } = edge;
        this.id = id;
        this.conversationId = conversationId;
        this.conversationItemId = conversationItemId;
        this.conversationItemOptionId = conversationItemOptionId;
        this.nextConversationItemId = nextConversationItemId;
        this.type = type;
        this.meta =
            typeof meta === 'string' ? JSON.parse(meta) : JSON.stringify(meta);
    }
}
