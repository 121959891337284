import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NodeList from './NodeList';
import ConversationSelection from './ConversationSelection';
import ActiveElementInfoPanel from './ActiveElementInfoPanel';
import { Button, Drawer, Divider, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { logout, isProd, getEnvSwitchUrl } from './../utils';
import { NavButtons } from '../constants';

const styles = () => ({
    toolbarContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    toolbarSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
});

class LeftPanel extends Component {
    state = {
        showDrawer: false,
        showDropdown: false,
        anchorEl: null,
    };

    handleLogout = () => {
        logout();
        this.props.logoutCallback();
        this.props.history.push('/');
    };

    handleSwitchChange = () => {
        window.location.href = getEnvSwitchUrl();
    };

    renderAuthButtons = () => {
        const { classes, history, isAuthorized } = this.props;
        return (
            <div>
                {isAuthorized ? (
                    <Button
                        color={'primary'}
                        onClick={this.handleLogout}
                        className={classes.logoutButton}
                        variant={'text'}
                        style={{ margin: 0 }}
                    >
                        Logout
                    </Button>
                ) : (
                    NavButtons.map(b => (
                        <Button
                            color={'primary'}
                            key={b.displayName}
                            onClick={() => history.push(b.url)}
                            style={{
                                marginTop: '10px',
                                backgroundColor: 'transparent',
                            }}
                            variant={'text'}
                        >
                            {b.displayName}
                        </Button>
                    ))
                )}
            </div>
        );
    };

    render() {
        const {
            addNode,
            classes,
            setActiveTab,
            activeTab,
            activeElement,
            replaceElement,
            elements,
            activeConversation,
        } = this.props;
        return (
            <Drawer open variant="permanent">
                <div className={classes.toolbarContainer}>
                    <div className={classes.toolbarSection}>
                        <Typography variant="body1">Admin Toolbar</Typography>
                        {this.renderAuthButtons()}
                    </div>
                    <div className={classes.toolbarSection}>
                        <div className={classes.toolbarSection}>
                            <Typography variant="body2">Dev</Typography>
                            <Switch
                                color="primary"
                                onChange={this.handleSwitchChange}
                                checked={isProd()}
                            />
                            <Typography variant="body2">Prod</Typography>
                        </div>
                    </div>
                </div>
                <Divider />
                <ConversationSelection
                    activeConversation={activeConversation}
                    handleActiveConversationChange={
                        this.props.handleActiveConversationChange
                    }
                />
                <Divider />
                <ActiveElementInfoPanel
                    replaceElement={replaceElement}
                    activeElement={activeElement}
                    activeConversation={activeConversation}
                    handleActiveConversationChange={
                        this.props.handleActiveConversationChange
                    }
                />
                <NodeList
                    elements={elements}
                    addNode={addNode}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    activeElement={activeElement}
                    replaceElement={replaceElement}
                    activeConversation={activeConversation}
                />
            </Drawer>
        );
    }
}

export default withRouter(withStyles(styles)(LeftPanel));

LeftPanel.propTypes = {
    appName: PropTypes.string.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
};
