import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    List,
    ListItemText,
    ListItem,
    ListItemIcon,
    Paper,
    Divider,
    Checkbox,
    Button,
    Tabs,
    Input,
    Tab,
    InputAdornment,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import { Search, Add } from '@material-ui/icons';
import {
    findIndex,
    cloneDeep,
    filter,
    includes,
    indexOf,
    lowerCase,
} from 'lodash';
import contentfulService from './../contentful';
import { updateLink } from './../api';
import { Edge } from '../models';

const styles = theme => ({
    authButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectedOverride: {
        backgroundColor: '#ffd757 !important',
        borderRadius: '4px',
    },
    list: {
        overflowY: 'auto',
        height: 'calc(100vh - 340px)',
    },
    listItem: {
        height: theme.spacing(8),
        minHeight: theme.spacing(8),
    },
    paddingLeft: {
        paddingLeft: 16,
    },
    logoutButton: {
        backgroundColor: 'transparent',
        marginTop: '10px',
        width: '60px',
        minWidth: '60px',
        fontSize: '20px',
    },
    cardHeader: {
        fontSize: 16,
    },
    titleMargins: {
        margin: theme.spacing(2),
    },
    conversationItemContainer: {
        width: '310px',
        bottom: 0,
    },
});

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}

class NodeList extends Component {
    state = {
        checked: [],
        conversationItems: [],
        conversationItemOptions: [],
        searchInput: '',
    };

    filterAlreadyUsedNodes(contentfulItems, usedItems) {
        //gets an arrow of conversationItemIds, map through all the conversationItemIds to see if they exist in the first array.
        // filter across the map for the undefined values
        // return new array.
        const currentlyUsedValues = usedItems.map(
            ({ conversationItemId }) => conversationItemId
        );
        const newConversationItems = contentfulItems
            .filter(item => {
                if (indexOf(currentlyUsedValues, item.sys.id) < 1) return item;
            })
            .filter(x => x);
        return newConversationItems;
    }

    fetchEntries = async () => {
        const [conversationItems, conversationItemOptions] = await Promise.all([
            contentfulService.getEntries({ content_type: 'conversationItem' }),
            contentfulService.getEntries({
                content_type: 'conversationItemOption',
            }),
        ]);
        this.setState({
            conversationItems: this.filterAlreadyUsedNodes(
                conversationItems.items,
                this.props.elements
            ),
            conversationItemOptions: conversationItemOptions.items,
        });
    };

    componentDidMount() {
        this.fetchEntries();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.elements.length !== this.props.elements.length) {
            this.setState({
                conversationItems: this.filterAlreadyUsedNodes(
                    this.state.conversationItems,
                    this.props.elements
                ),
            });
        }
        if (prevProps.activeConversation !== this.props.activeConversation) {
            this.fetchEntries();
        }
    }

    numberOfSelectedItems = items => {
        return intersection(this.state.checked, items).length;
    };

    handleToggle = value => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checked: newChecked });
    };

    handleAddNode = () => {
        const { checked, conversationItems } = this.state;
        this.props.addNode(checked);
        const newConversationItemList = cloneDeep(conversationItems);

        // remove them from the list
        this.state.checked.forEach(markedItem => {
            const index = findIndex(newConversationItemList, markedItem);
            newConversationItemList.splice(index, 1);
        });
        // adds replaces current conversationItems with the newly spliced list and resets checked array.
        this.setState({
            conversationItems: newConversationItemList,
            checked: [],
        });
    };

    setOption = async option => {
        const { replaceElement } = this.props;
        // being passed an option, but we need to associate this to the source node.
        const updateOption = new Edge({
            ...this.props.activeElement,
            conversationItemOptionId: option.sys.id,
            meta: {
                ...this.props.activeElement.meta,
                label: option.fields.option,
            },
        });

        const { result } = await updateLink(updateOption);

        replaceElement({ element: new Edge(result), isDelete: false });
    };

    listItems = () => {
        const { conversationItemOptions, conversationItems } = this.state;
        const { activeTab } = this.props;
        const items =
            activeTab === 'conversationItem'
                ? conversationItems
                : conversationItemOptions;
        return (
            <>
                <ListItem>
                    <Input
                        placeholder="Search Here"
                        value={this.state.searchInput}
                        onChange={({ target: { value } }) => {
                            this.setState({ searchInput: value });
                        }}
                        variant="filled"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </ListItem>
                {filter(
                    items,
                    item =>
                        this.state.searchInput === '' ||
                        (activeTab === 'conversationItem'
                            ? includes(
                                  lowerCase(item.fields.title),
                                  lowerCase(this.state.searchInput)
                              )
                            : includes(
                                  lowerCase(item.fields.option),
                                  lowerCase(this.state.searchInput)
                              ))
                ).map(item => {
                    const labelId = `all-item-${item.sys.id}-label`;
                    return (
                        <div key={item.sys.id}>
                            {activeTab === 'conversationItem' ? (
                                <ListItem
                                    role="listitem"
                                    button
                                    onClick={this.handleToggle(item)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                this.state.checked.indexOf(
                                                    item
                                                ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText key={item.sys.id}>
                                        {item.fields.title}
                                    </ListItemText>
                                </ListItem>
                            ) : (
                                <ListItem dense>
                                    <ListItemText
                                        id={item.sys.id}
                                        primary={`${item.fields.option}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="Add Option"
                                            onClick={() => this.setOption(item)}
                                        >
                                            <Add />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    render() {
        const { classes, activeTab, setActiveTab } = this.props;
        return (
            <>
                <Divider />
                <Paper className={classes.conversationItemContainer}>
                    <Tabs
                        value={activeTab}
                        onChange={(e, value) => setActiveTab(e, value)}
                    >
                        <Tab
                            wrapped
                            label={
                                <span>
                                    {`Items`} <br />
                                    {`${
                                        this.state.checked.filter(
                                            item =>
                                                item.sys.contentType.sys.id ===
                                                'conversationItem'
                                        ).length
                                    } / ${this.state.conversationItems.length}`}
                                </span>
                            }
                            value={'conversationItem'}
                            style={{
                                minWidth: 100,
                                alignItems: 'flex-start',
                                fontSize: 12,
                            }}
                        />
                        <Tab
                            wrapped
                            label={
                                <span>
                                    {`Options`} <br />
                                    {`${
                                        this.state.checked.filter(
                                            item =>
                                                item.sys.contentType.sys.id ===
                                                'conversationItemOption'
                                        ).length
                                    } / ${
                                        this.state.conversationItemOptions
                                            .length
                                    }`}
                                </span>
                            }
                            value={'conversationItemOption'}
                            style={{
                                minWidth: 100,
                                alignItems: 'flex-start',
                                fontSize: 12,
                            }}
                            disabled={
                                !(
                                    this.props.activeTab ===
                                    'conversationItemOption'
                                )
                            }
                        />
                    </Tabs>
                    <List className={classes.list} dense>
                        {this.state.checked.length > 0 && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleAddNode}
                                style={{ margin: 5 }}
                            >
                                {this.state.checked.length === 1
                                    ? 'Add Node'
                                    : 'Add Nodes'}
                            </Button>
                        )}

                        {this.listItems()}
                    </List>
                </Paper>
            </>
        );
    }
}

NodeList.defaultProps = {
    elements: [],
};

NodeList.propTypes = {
    elements: PropTypes.array.isRequired,
};

export default withStyles(styles)(withRouter(NodeList));
