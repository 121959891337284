import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    FormControl,
    FormHelperText,
    Select,
    InputLabel,
    MenuItem,
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@material-ui/core';
import {
    getConversations,
    updateConversation,
    createConversation,
    getClientList,
    createClient,
} from './../api';
import { Settings, Add } from '@material-ui/icons';
import { find, get } from 'lodash';
import EMSnackbar from './EMSnackbar';

const styles = theme => ({
    formControlContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'inline-flex',
        width: 150,
    },
    settingsContainer: {
        display: 'inline-block',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
});

class ConversationSelection extends Component {
    state = {
        conversations: [],
        isMenuOpen: false,
        anchorEl: null,
        isProgressBarModalOpen: false,
        progressBarTitles: '',
        isAddConversationModalOpen: false,
        conversationNameInput: '',
        selectedClient: {},
        clients: [],
        isNewClient: false,
        newClientInput: '',
        snackbar: {
            showSnackBar: false,
            variant: 'success',
            message: 'Success',
        },
    };

    async componentDidMount() {
        const theConversation = window.localStorage.getItem(
            'activeConversation'
        );
        const [clients, conversations] = await Promise.all([
            getClientList(),
            getConversations(),
        ]);

        if (theConversation) {
            this.props.handleActiveConversationChange(
                JSON.parse(theConversation)
            );
            this.setState({
                conversations: conversations.result,
                progressBarTitles:
                    get(theConversation, 'meta.progressBarTitles') || '',
                clients: clients.result,
            });
        } else {
            this.props.handleActiveConversationChange(conversations.result[0]);
            this.setState({
                conversations: conversations.result,
                clients: clients.result,
            });
        }
    }

    successSnack = message => {
        this.setState({
            ...this.state,
            snackbar: {
                showSnackBar: true,
                variant: 'success',
                message,
            },
        });
    };

    errorSnack = message => {
        this.setState({
            ...this.state,
            snackbar: {
                snowSnackBar: false,
                variant: 'error',
                message,
            },
        });
    };
    handleConversationSelection = ({ target: { value } }) => {
        const theConversation = find(this.state.conversations, { name: value });
        this.props.handleActiveConversationChange(theConversation);
    };

    submitProgressBarTitle = async () => {
        const progressBarTitles = this.state.progressBarTitles.split(',');
        const results = await updateConversation({
            conversation: {
                ...this.props.activeConversation,
                meta: JSON.stringify({ progressBarTitles }),
            },
        });
        if (results.error) {
            this.errorSnack(results.error);
        } else {
            this.successSnack('progress bar titles updated');
        }
        this.props.handleActiveConversationChange(results);
        this.closeProgressBarTitleModal();
    };

    submitCreateConversation = async () => {
        //TODO: refresh the list of conversations with the returned result. For now, a refresh will add it.

        if (!this.state.isNewInput) {
            await createConversation({
                subdomain: this.state.conversationNameInput,
                clientId: this.state.selectedClient.id,
            });
        } else {
            // have to create the conversation first, then we can create the conversation with the returned
            // converstion id.
            const client = await createClient({
                name: this.state.newClientInput,
            });

            if (client.error) {
                this.errorSnack(client.error);
                return;
            }

            const newConversation = await createConversation({
                subdomain: this.state.conversationNameInput,
                clientId: client.result.id,
            });

            if (newConversation.error) {
                this.errorSnack(newConversation.error);
                return;
            }
        }

        const [clients, conversations] = await Promise.all([
            getClientList(),
            getConversations(),
        ]);

        this.setState({
            ...this.state,
            isAddConversationModalOpen: false,
            conversations: conversations.result,
            clients: clients.result,
            conversationNameInput: '',
            snackbar: {
                showSnackBar: true,
                variant: 'success',
                message: 'Created',
            },
        });
    };

    openProgressBarTitleModal = () => {
        const titles = get(
            this.props.activeConversation,
            'meta.progressBarTitles'
        );
        this.setState({
            ...this.state,
            isProgressBarModalOpen: true,
            progressBarTitles: titles ? titles.join() : '',
        });
    };

    closeProgressBarTitleModal = () => {
        this.setState({
            ...this.state,
            isProgressBarModalOpen: false,
        });
    };

    maybeRenderSnackbar = () => {
        const { variant, message, showSnackBar } = this.state.snackbar;
        return (
            <EMSnackbar
                variant={variant}
                message={message}
                handleClose={() => this.setState({ showSnackBar: false })}
                isOpen={showSnackBar}
            />
        );
    };

    maybeRenderAddConversationModal = () => {
        const { classes } = this.props;
        return (
            <>
                <Dialog
                    open={this.state.isAddConversationModalOpen}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            isAddConversationModalOpen: false,
                        })
                    }
                >
                    <DialogTitle id="title">Add A Conversation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Conversation names must be unique across all
                            conversations
                        </DialogContentText>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="name"
                                label="Conversation Name"
                                fullWidth
                                value={this.state.conversationNameInput}
                                onChange={e => {
                                    this.setState({
                                        ...this.state,
                                        conversationNameInput: e.target.value,
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            {!this.state.isNewInput ? (
                                <>
                                    <InputLabel id="Client">Client</InputLabel>
                                    <Select
                                        labelId="Client"
                                        id="Client"
                                        value={this.state.selectedClient}
                                        renderValue={value => value.subdomain}
                                        onChange={({ target: { value } }) =>
                                            this.setState({
                                                ...this.state,
                                                selectedClient: value,
                                            })
                                        }
                                    >
                                        {this.state.clients.length &&
                                            this.state.clients.map(
                                                (client, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={client}
                                                    >
                                                        {client.subdomain}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </>
                            ) : (
                                <TextField
                                    label="New Client"
                                    fullWidth
                                    value={this.state.newClientInput}
                                    onChange={e => {
                                        this.setState({
                                            ...this.state,
                                            newClientInput: e.target.value,
                                        });
                                    }}
                                />
                            )}
                            <FormHelperText>
                                {!this.state.isNewInput
                                    ? `Click "Add New Client" if this is the first conversation for a client`
                                    : 'Input name of the new client. The app will create the client first, then associate the conversation with this client'}
                            </FormHelperText>
                            <Button
                                onClick={() =>
                                    this.setState({
                                        ...this.state,
                                        isNewInput: !this.state.isNewInput,
                                    })
                                }
                            >
                                {!this.state.isNewInput
                                    ? `Add New Client`
                                    : `Select existing client`}
                            </Button>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                this.setState({
                                    ...this.state,
                                    isAddConversationModalOpen: false,
                                    conversationNameInput: '',
                                })
                            }
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.submitCreateConversation}
                            color="primary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    maybeRenderProgressBarModal = () => {
        return (
            <>
                <Dialog
                    open={this.state.isProgressBarModalOpen}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            isProgressBarModalOpen: false,
                        })
                    }
                >
                    <DialogTitle id="dialog-title">
                        Set Progress Bar Title For Conversation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter up to 5 progress bar values that are
                            comma separated.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Progress Bar Titles"
                            fullWidth
                            value={this.state.progressBarTitles}
                            onChange={e => {
                                e.preventDefault();
                                this.setState({
                                    ...this.state,
                                    progressBarTitles: e.target.value,
                                });
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeProgressBarTitleModal}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.submitProgressBarTitle}
                            color="primary"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    render() {
        const { classes } = this.props;
        const { conversations } = this.state;

        return (
            <div style={{ display: 'inline-block' }}>
                <div className={classes.formControlContainer}>
                    <FormControl fullWidth>
                        <InputLabel>Conversation</InputLabel>
                        <Select
                            value={this.props.activeConversation.name}
                            onChange={(selection, e) =>
                                this.handleConversationSelection(selection, e)
                            }
                        >
                            {conversations.map((conversation, i) => {
                                return (
                                    <MenuItem key={i} value={conversation.name}>
                                        {conversation.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.settingsContainer}>
                    <div style={{ display: 'inline-block' }}>
                        <Button
                            style={{ display: 'inline-block' }}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    isAddConversationModalOpen: true,
                                    selectedClient: find(this.state.clients, {
                                        id: this.props.activeConversation
                                            .clientId,
                                    }),
                                });
                            }}
                        >
                            <Add />
                        </Button>
                        <Button
                            ref={this.state.anchorEl}
                            aria-controls={
                                this.state.isMenuOpen
                                    ? 'menu-list-grow'
                                    : undefined
                            }
                            aria-haspopup="true"
                            onClick={this.openProgressBarTitleModal}
                            style={{ display: 'inline-block' }}
                        >
                            <Settings />
                        </Button>
                    </div>
                </div>
                {this.maybeRenderAddConversationModal()}
                {this.maybeRenderProgressBarModal()}
                {this.maybeRenderSnackbar()}
            </div>
        );
    }
}
export default withStyles(styles)(ConversationSelection);
