import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
    withRouter,
} from 'react-router-dom';
import { find } from 'lodash';
import { Authentication, Home } from './pages';
import { isLoggedIn, setTrackingCookies } from './utils';
import { RoutesWithoutNav } from './constants';
import { authenticate } from './api';
import './App.css';

const styles = theme => ({
    leftNavPanelRendered: {
        marginLeft: '0px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '300px',
        },
    },
    notLeftNavPanelRendered: {
        marginLeft: '0px',
    },
    toolbarLeftNavPanelRendered: {
        visibility: 'hidden',
        [theme.breakpoints.down('xs')]: {
            ...theme.mixins.toolbar,
            visibility: 'visible',
        },
    },
    toolbarNotLeftNavPanelRendered: theme.mixins.toolbar,
});

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn() === true ? (
                <Component {...props} />
            ) : (
                <Redirect to="/sign-in" />
            )
        }
    />
);

class App extends Component {
    state = {
        isLoggedIn: isLoggedIn(),
    };

    constructor(props) {
        super(props);
        setTrackingCookies();
    }

    logout = () => {
        this.setState({ isLoggedIn: false });
    };

    login = async () => {
        this.setState({ isLoggedIn: true });
    };

    shouldRenderLeftNavPanel = () => {
        return !find(RoutesWithoutNav, x => x === window.location.pathname);
    };

    getSignUp = () => {
        if (isLoggedIn()) {
            return <Redirect to="/" />;
        } else {
            return (
                <Authentication
                    authenticatedCallback={this.login}
                    isSignUp={true}
                    redirectToOnSuccess={'/'}
                    authenticate={authenticate}
                />
            );
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="App" style={{ height: '100%' }}>
                <div
                    className={
                        this.shouldRenderLeftNavPanel()
                            ? classes.toolbarLeftNavPanelRendered
                            : classes.toolbarNotLeftNavPanelRendered
                    }
                />
                <div
                    className={`main-content ${
                        this.shouldRenderLeftNavPanel()
                            ? classes.leftNavPanelRendered
                            : classes.notLeftNavPanelRendered
                    }`}
                >
                    <Switch>
                        <AuthenticatedRoute
                            exact
                            path="/"
                            component={() => (
                                <Home
                                    isAuthorized={this.state.isLoggedIn}
                                    logoutCallback={this.logout}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/sign-in"
                            render={() =>
                                isLoggedIn() ? (
                                    <Redirect to="/" />
                                ) : (
                                    <Authentication
                                        authenticatedCallback={this.login}
                                        redirectToOnSuccess={'/'}
                                        authenticate={authenticate}
                                    />
                                )
                            }
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}

const AppWithRouter = withRouter(withStyles(styles)(App));

class AppWrapper extends React.Component {
    render() {
        return (
            <Router>
                <AppWithRouter />
            </Router>
        );
    }
}

export default AppWrapper;
