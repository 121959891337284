import Cookies from 'js-cookie';
import { uuid } from 'uuidv4';
import { refreshTokens } from './api';

const env = process.env.REACT_APP_EM_ENV;

export const getCookieDomain = () => {
    let domain = '';
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        domain = 'admin.entermedicare.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        domain = 'admin.entermedicare-dev.com';
    }
    return domain;
};

export const setTrackingCookies = () => {
    let sessionId = Cookies.get('sid');
    let trackingId = Cookies.get('tid');

    if (!sessionId) {
        sessionId = uuid();
        Cookies.set('sid', sessionId, {
            expires: 1,
        }); // 1 days
    }

    if (!trackingId) {
        trackingId = uuid();
        Cookies.set('tid', trackingId, {
            expires: 30,
        }); // 30 days
    }
};

export const getElementInfoFromId = id => {
    const splitArray = id.split('_');
    return {
        contentType: splitArray[0],
        itemId: splitArray[1],
    };
};

export const getTrackingIds = () => {
    const sessionId = Cookies.get('sid');
    const trackingId = Cookies.get('tid');
    return { sessionId, trackingId };
};

export const apiUrlForEnv = () => {
    let config = {};
    if (env === 'prod') {
        config = {
            apiUrl: 'https://api.entermedicare.com/',
            contentfulEnv: 'master',
        };
    } else if (env === 'dev') {
        config = {
            apiUrl: 'https://api.entermedicare-dev.com/',
            contentfulEnv: 'develop',
        };
    } else if (env === 'local') {
        config = {
            apiUrl: 'http://localhost:3002/',
            contentfulEnv: 'develop',
        };
    }

    return config;
};

export const createEdgeAndNodeArray = conversationRouting => {
    const edgeAndNodeArray = [];
    conversationRouting.forEach(node => {
        edgeAndNodeArray.push(node);
        if (node.edges.length > 0) {
            node.edges.forEach(edge => edgeAndNodeArray.push(edge));
        }
    });
    return edgeAndNodeArray;
};

export const urlForEnv = () => {
    let link = '';
    if (env === 'prod') {
        link = 'https://admin.entermedicare.com/';
    } else if (env === 'dev') {
        link = 'https://admin.entermedicare-dev.com/';
    } else {
        link = 'http://localhost:3004/';
    }
    return link;
};

export const isLoggedIn = () => {
    const adminAccessToken = Cookies.get('adminAccessToken');
    if (adminAccessToken) {
        const expiry = adminAccessToken.split('@')[1];
        if (Date.now() < expiry) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const logout = () => {
    Cookies.remove('adminAccessToken');
    Cookies.remove('refreshToken');
    clearInterval(refreshToken());
    localStorage.clear();
};

export const refreshToken = () => {
    const refreshTokenInterval = setInterval(() => {
        if (Cookies.get('refreshToken')) {
            refreshTokens(Cookies.get('refreshToken'));
        }
    }, 840000);
    return refreshTokenInterval;
};

export const getEnvSwitchUrl = () => {
    if (env === 'prod') {
        return 'https://admin-dev.entermedicare.com';
    } else {
        return 'https://admin.entermedicare.com';
    }
};

export const isProd = () => {
    return env === 'prod';
};
