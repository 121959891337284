import React from 'react';
import ReactFlow, { Background, MiniMap, Controls } from 'react-flow-renderer';
import LeftPanel from '../components/LeftPanel';
import {
    getConversationRouting,
    addNode,
    updateLink,
    addEdge,
    removeLink,
} from '../api';
import { Node, Edge } from '../models';
import ConversationItemNode from '../components/ConversationItemNode';
import ConversationItemOptionEdge from '../components/ConversationItemOptionEdge';
import { get, find, findIndex, cloneDeep } from 'lodash';
import { getElementInfoFromId, createEdgeAndNodeArray } from '../utils';
const nodeTypes = {
    ConversationItemNode,
};

const edgeTypes = {
    ConversationItemOptionEdge,
};
class Home extends React.Component {
    state = {
        activeConversation: {
            id: 1,
            name: 'MVP Conversation',
        },
        activeTab: 'conversationItem',
        flowList: [],
        elements: [],
        activeElement: {},
    };

    onElementClick = async (e, element) => {
        const fullElement = find(this.state.elements, {
            meta: { id: element.id },
        });
        if (fullElement instanceof Node) {
            this.setState({
                activeElement: fullElement,
                activeTab: 'conversationItem',
            });
        } else if (fullElement instanceof Edge) {
            this.setState({
                activeElement: fullElement,
                activeTab: 'conversationItemOption',
            });
        }
    };

    onNodeDragStop = async (e, element) => {
        //update the node in the backend
        const conversationItemId = getElementInfoFromId(element.id).itemId;
        const conversationElement = find(this.state.elements, x => {
            if (
                x instanceof Node &&
                x.conversationItemId === conversationItemId
            )
                return x;
        });
        const index = findIndex(this.state.elements, conversationElement);
        const elementsCopy = cloneDeep(this.state.elements);
        if (
            conversationElement.meta.position.x !== element.position.x ||
            conversationElement.meta.position.y !== element.position.y
        ) {
            const mergedNode = new Node(
                {
                    ...conversationElement,
                    conversationId: this.state.activeConversation.id,
                    meta: {
                        ...conversationElement.meta,
                        position: element.position,
                    },
                },
                true
            );
            await updateLink(mergedNode);
            elementsCopy.splice(index, 1);
            elementsCopy.push(new Node(mergedNode, false));
            this.setState(
                {
                    elements: elementsCopy,
                },
                () => console.log('position updated')
            );
        }
    };

    handleTabChange = async (e, value) => {
        this.setState({ activeTab: value });
    };

    handleActiveConversationChange = async conversation => {
        // grab the conversationElements from api. commenting this out until we need it.
        const { result } = await getConversationRouting(
            get(conversation, 'id') || this.state.activeConversation.id
        );

        const elements = createEdgeAndNodeArray(result);
        window.localStorage.setItem(
            'activeConversation',
            JSON.stringify(conversation)
        );

        //once we have elements, we should manimuplate elements. Maybe we should store X Y too... so elements don't jump around.
        this.setState({ activeConversation: conversation, elements }, () => {});
    };

    handleConnection = async ({ source, target }) => {
        const currentElements = cloneDeep(this.state.elements);
        const edgeId = `option_${source}_${target}`;
        const edge = {
            conversationId: this.state.activeConversation.id,
            conversationItemId: getElementInfoFromId(source).itemId,
            conversationItemOptionId: null,
            nextConversationItemId: getElementInfoFromId(target).itemId,
            meta: {
                id: edgeId,
                type: 'ConversationItemOptionEdge',
                source,
                target,
                label: 'New Edge',
            },
        };
        const currentMetaList = this.state.elements.map(el => el.meta);
        const connectionExist = find(currentMetaList, { id: edgeId });
        if (connectionExist) {
            alert('this connection has already been made');
        } else {
            const { result } = await addEdge(new Edge(edge));
            currentElements.push(result);
            this.setState({ elements: currentElements });
        }
    };

    replaceElement = async ({ element, isDelete }) => {
        const index = findIndex(
            this.state.elements,
            el => el.id === element.id
        );
        if (index < 0) {
            alert('Sorry, an error occured. That element could not be found');
            return;
        }
        const copy = cloneDeep(this.state.elements);
        if (isDelete) {
            //check to see if the index element is used anywhere else.
            const foundElement = get(
                this.state.elements[index],
                'conversationItemId'
            );
            if (!foundElement) {
                alert('sorry, no conversationItemId was found');
                return;
            }
            const edgeExists = find(
                this.state.elements,
                x =>
                    x.meta.source === 'item_' + foundElement ||
                    x.meta.target === 'item_' + foundElement
            );

            if (edgeExists && this.state.elements[index] instanceof Node) {
                alert(
                    'Please remove all edges attached to this node before attempting to delete it'
                );
            } else {
                copy.splice(index, 1);
                await removeLink({ routingId: element.id });
            }
        } else {
            copy.splice(index, 1, element);
        }
        this.setState({
            elements: copy,
        });
        // deepcopies weren't triggering update
        this.forceUpdate();
    };

    addNodes = async elements => {
        const { activeConversation } = this.state;

        const promiseArray = [];

        elements.forEach(element => {
            const conversationItemId = element.sys.id;
            const node = {
                conversationId: activeConversation.id,
                id: conversationItemId,
                conversationItemId,
                nextConversationItemId: null,
                skipIfAnswered: false,
                progressValue: null,
                meta: {
                    id: `item_${element.sys.id}`,
                    sourcePosition: 'top',
                    targetPosition: 'bottom',
                    type: 'ConversationItemNode',
                    data: {
                        label: element.fields.title,
                        sources: [
                            {
                                id: '0',
                            },
                        ],
                    },
                    position: {
                        x: 150 + Math.round(50 * Math.random()),
                        y: 150 + Math.round(75 * Math.random()),
                    },
                },
            };
            const tempNode = new Node(node, true);
            promiseArray.push(addNode(tempNode));
            return node;
        });

        const res = await Promise.all(promiseArray);
        const newElementsList = cloneDeep(this.state.elements).concat(
            res.map(({ result }) => {
                result.meta.data.routingId = result.id;
                return result;
            })
        );
        this.setState({ elements: newElementsList });
    };

    async componentDidMount() {
        //grab the conversationItemGraph from api.
        // set the activeConversation on initial.

        const cachedActiveConversation = JSON.parse(
            window.localStorage.getItem('activeConversation')
        );
        if (cachedActiveConversation) {
            this.setState({
                activeConversation: cachedActiveConversation,
            });
        } else {
            await getConversationRouting(1);
        }
    }

    formatElements = elements => {
        return elements ? elements.map(element => element.meta) : [];
    };

    render() {
        return (
            <div style={{ height: '100vh' }}>
                <LeftPanel
                    isAuthorized={this.props.isAuthorized}
                    appName={'New Admin Panel'}
                    logoutCallback={this.props.logoutCallback}
                    activeConversation={this.state.activeConversation}
                    activeElement={this.state.activeElement}
                    activeTab={this.state.activeTab}
                    setActiveTab={this.handleTabChange}
                    addNode={this.addNodes}
                    replaceElement={this.replaceElement}
                    handleActiveConversationChange={
                        this.handleActiveConversationChange
                    }
                    elements={this.state.elements}
                />
                <ReactFlow
                    elements={this.formatElements(this.state.elements)}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onElementClick={this.onElementClick}
                    onConnect={this.handleConnection}
                    onNodeDragStop={this.onNodeDragStop}
                    snapToGrid
                >
                    <MiniMap />
                    <Background gap={12} size={1} />
                    <Controls />
                </ReactFlow>
            </div>
        );
    }
}

export default Home;
