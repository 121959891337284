export class Conversation {
    constructor(convo) {
        const {
            id,
            name,
            subdomain,
            firstItemId,
            isActive,
            clientId,
            meta,
        } = convo;
        this.id = id;
        this.name = name;
        this.subdomain = subdomain;
        this.firstItemId = firstItemId;
        this.isActive = isActive;
        this.clientId = clientId;
        this.meta =
            typeof meta === 'string' ? JSON.parse(meta) : JSON.stringify(meta);
    }
}
