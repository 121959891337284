import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EMSnackbar = props => {
    return (
        <Snackbar
            open={props.isOpen}
            autoHideDuration={6000}
            onClose={props.handleClose}
        >
            <Alert onClose={props.handleClose} severity={props.variant}>
                {props.message}
            </Alert>
        </Snackbar>
    );
};

export default EMSnackbar;
