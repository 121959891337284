import React from 'react';
import { purple } from '@material-ui/core/colors';
import { Handle } from 'react-flow-renderer';

export default meta => {
    const { data } = meta;
    return (
        <div
            style={{
                background: purple[100],
                border: '1px solid #777',
                padding: 10,
                minWidth: 125,
            }}
        >
            {data.sources.map((source, i) => {
                return (
                    <Handle
                        key={i}
                        type="target"
                        position="top"
                        id={source.id}
                        style={{
                            background: '#111',
                            marginLeft: i * 25,
                            left: 'auto',
                        }}
                    />
                );
            })}
            <div style={{ textAlign: 'center' }}>
                <strong>{data.label}</strong>
            </div>

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#111' }}
            />
        </div>
    );
};
