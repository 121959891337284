import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Link,
    Typography,
    withStyles,
    Button,
    TextField,
} from '@material-ui/core';
import EMSnackbar from '../components/EMSnackbar';
import { refreshToken } from '../utils';
import Shoes from '../assets/Shoes.png';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        fontFamily: 'Halant, serif',
    },
    shoes: {
        margin: 10,
    },
    submit: {
        margin: '10px 0px !important',
    },
});

class Authentication extends Component {
    state = {
        email: '',
        error: '',
        isPasswordValid: false,
        password: '',
        showError: false,
    };

    handleOnSubmit = async e => {
        this.setState({ showError: false });
        e.preventDefault();
        const { email, password } = this.state;
        const { result, error } = await this.props.authenticate({
            email,
            password,
        });
        if (error) {
            this.setState({ showError: true, error });
        } else {
            refreshToken();
            this.props.authenticatedCallback({ userId: result.userData.id });
            this.props.history.push(this.props.redirectToOnSuccess);
        }
    };

    handleEmailChange = ({ target: { value } }) => {
        this.setState({ email: value });
    };

    handlePasswordChange = ({ target: { value } }) => {
        this.setState({ password: value });
    };

    handlePasswordIsValidChange = valid => {
        if (valid !== this.state.isPasswordValid) {
            this.setState({
                isPasswordValid: valid,
            });
        }
    };

    maybeRenderError = () => {
        return (
            <EMSnackbar
                variant={'error'}
                message={this.state.error}
                isOpen={this.state.showError}
                handleClose={() => this.setState({ showError: false })}
            />
        );
    };

    renderForgotPassword = () => {
        return (
            <Link href="/forgot-password" variant="body2">
                Forgot password?
            </Link>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img
                        src={Shoes}
                        width="100"
                        className={classes.shoes}
                        alt={`Entermedicare Shoes`}
                    />
                    <Typography
                        component="h1"
                        variant="h5"
                        className={classes.title}
                    >
                        {'Sign in'}
                    </Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.handleOnSubmit}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={this.handleEmailChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handlePasswordChange}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Checkbox value="remember" color="secondary" />
                            }
                            label="Remember me"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={x => this.handleOnSubmit(x)}
                        >
                            Sign in
                        </Button>
                        {this.renderForgotPassword()}
                    </form>
                </div>
                {this.maybeRenderError()}
            </Container>
        );
    }
}

Authentication.propTypes = {
    redirectToOnSuccess: PropTypes.string.isRequired,
    authenticate: PropTypes.func.isRequired,
    authenticatedCallback: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Authentication));
