import { createClient } from 'contentful-management';
import * as contentful from 'contentful';
import { map } from 'lodash';
import { apiUrlForEnv } from './utils';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const CONTENTFUL_ENV = apiUrlForEnv().contentfulEnv;
const deliveryClient = contentful.createClient({
    space: SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY,
    environment: CONTENTFUL_ENV,
});
const managementClient = createClient({
    accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_API_KEY,
});

class ContentfulService {
    async build() {
        this.space = await managementClient.getSpace(SPACE_ID);
        this.environment = await this.space.getEnvironment(CONTENTFUL_ENV);
    }

    async createEntry(contentType, entryId, fields) {
        if (!this.environment) {
            await this.build();
        }
        return this.environment
            .createEntryWithId(contentType, entryId, fields)
            .then(entry => {
                map(
                    fields,
                    (field, key) => (entry.fields[key] = { 'en-US': field })
                );
                return entry.update();
            })
            .then(updatedEntry => updatedEntry.publish())
            .catch(console.error);
    }

    async updateEntry(entryId, fields) {
        if (!this.environment) {
            await this.build();
        }
        return this.environment
            .getEntry(entryId)
            .then(entry => {
                map(fields, (field, key) => (entry.fields[key] = field));
                return entry.update();
            })
            .then(updatedEntry => updatedEntry.publish())
            .catch(console.error);
    }

    getEntries(options) {
        const optionsWithLimits = { ...options, limit: 500 };
        return deliveryClient
            .getEntries(optionsWithLimits)
            .then(entries => entries)
            .catch(console.error);
    }

    getEntryById(entryId) {
        return deliveryClient.getEntry(entryId).catch(console.error);
    }
}

const contentfulService = new ContentfulService();

export default contentfulService;
