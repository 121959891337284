import React from 'react';
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer';

export default function ConversationItemOptionEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {
        width: '10px',
        color: 'red',
    },
    arrowHeadType,
    markerEndId,
    label,
}) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <text>
                <textPath
                    href={`#${id}`}
                    style={{ fontSize: '18px', opacity: '100%' }}
                    startOffset="50%"
                    textAnchor="middle"
                >
                    {label}
                </textPath>
            </text>
        </>
    );
}
