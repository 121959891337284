import axios from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import { logout } from './utils';
import { Conversation, Node, Edge } from './models';
import { getCookieDomain } from './utils';

axios.interceptors.request.use(
    config => {
        let apiUrl = 'http://localhost:3002';

        if (process.env.REACT_APP_EM_ENV === 'prod') {
            apiUrl = 'https://api.entermedicare.com';
        }
        if (process.env.REACT_APP_EM_ENV === 'dev') {
            apiUrl = 'https://api.entermedicare-dev.com';
        }

        config.url = apiUrl + config.url;

        config.headers.Accept = 'application/json';
        config.headers.Authorization = Cookies.get('adminAccessToken');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            logout();
        } else if (error.response.status === 403) {
            logout();
            window.location.href = '/sign-in';
        }
        return Promise.reject(error);
    }
);

export const authenticate = async ({ email, password }) => {
    const options = {
        method: 'POST',
        data: {
            identifier: email,
            password,
        },
        url: '/auth',
    };

    try {
        const result = await axios(options);
        Cookies.set('adminAccessToken', result.data.accessToken, {
            expires: 1,
            domain: getCookieDomain(),
        });
        Cookies.set('adminRefreshToken', result.data.refreshToken, {
            expires: 30,
            domain: getCookieDomain(),
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: 'Invalid email or password',
        };
    }
};

export const refreshTokens = async refreshToken => {
    try {
        const result = await axios.post('/auth/refresh', {
            refreshToken,
        });
        Cookies.set('adminAccessToken', result.data.accessToken, {
            expires: 1,
            domain: getCookieDomain(),
        });
        Cookies.set('adminRefreshToken', result.data.refreshToken, {
            expires: 30,
            domain: getCookieDomain(),
        });
        return null;
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to refresh token'),
        };
    }
};

export const getConversations = async () => {
    try {
        const result = await axios.get('/conversation');
        const conversations = result.data
            ? result.data.map(conversation => new Conversation(conversation))
            : [];
        return { result: conversations };
    } catch (e) {
        return {
            error: get(e, 'response.data.message'),
        };
    }
};

export const createClient = async ({ name }) => {
    try {
        const { data } = await axios.post('/client', { name });
        return { result: data };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const getClientList = async () => {
    try {
        const { data } = await axios.get('/client/list');
        return { result: data };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const createConversation = async ({ clientId, subdomain }) => {
    try {
        const result = await axios.post('/subdomain/create', {
            clientId,
            subdomain,
        });
        return { result };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const updateConversation = async ({ conversation }) => {
    try {
        const { data } = await axios.put('/conversation', conversation);
        return new Conversation(data);
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const getConversationRouting = async conversationId => {
    try {
        const result = await axios.get(
            `/conversation/routing/${conversationId}`
        );
        const nodes = result.data
            ? result.data.map(node => new Node(node, false))
            : [];
        return { result: nodes };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const addNode = async node => {
    try {
        const { data } = await axios.post(`/conversation/routing/node`, node);
        return { result: data ? new Node(data, false) : null };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const addEdge = async ({
    conversationId,
    conversationItemId,
    conversationItemOptionId,
    nextConversationItemId,
    type,
    meta,
}) => {
    try {
        const result = await axios.post(`/conversation/routing/edge`, {
            conversationId,
            conversationItemId,
            conversationItemOptionId,
            nextConversationItemId,
            type,
            meta,
        });
        const edge = result.data ? new Edge(result.data) : null;
        return { result: edge };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

// Where a "link" is either an edge or a node
export const updateLink = async ({
    id, //routingId
    conversationItemId,
    conversationItemOptionId,
    nextConversationItemId,
    skipIfAnswered,
    progressValue,
    meta,
}) => {
    try {
        const result = await axios.put(`/conversation/routing/`, {
            routingId: id, //backend expects this as routingId.
            conversationItemId,
            conversationItemOptionId,
            nextConversationItemId,
            skipIfAnswered,
            progressValue,
            meta,
        });
        return { result: result.data };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};

export const removeLink = async ({ routingId }) => {
    try {
        await axios.delete(`/conversation/routing/${routingId}`);
        return { result: null };
    } catch (e) {
        return { error: get(e, 'response.data.message') };
    }
};
