export const AppName = 'Entermedicare';
export const DrawerMarginLeft = 240;
export const DrawerMarginItem = 280;
export const NavBarHeight = 64;
export const EMYellow = '#ffd757';
export const EMPurple = '#342996';
export const DrawerItems = [
    {
        displayName: 'Home',
        url: '/',
        isExternal: false,
    },
];

export const RoutesWithoutNav = ['/sign-in', '/404'];

export const NavButtons = [
    {
        displayName: 'Sign in',
        url: '/sign-in',
        isAuthroized: false,
    },
];
